import React from 'react'
import Navigation from './components/Navigation';
import Banner from './components/Banner';
import Skills from './components/Skills'
import Projects from './components/Projects/Projects'
import Marquee from './components/Marquee/Marquee'
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <div className='App'>
        <Navigation />
        <Banner />
        <Skills />
        <Projects />
        <Marquee />
    </div>
    
  )
}

export default App;
