import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';

const Navigation = () => {
	const [activeLink, setActiveLink] = useState('home');
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			setScrolled(false);
			if (window.scrollY > 50) {
				setScrolled(true);
			}
		}

		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	const onUpdateActiveLink = (value) => {
		setActiveLink(value);
	}

	return (
		<Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
			<Container>
				<Navbar.Toggle aria-controls="basic-navbar-nav">
					<span className='navbar-toggler-icon'></span>
				</Navbar.Toggle>
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav>
						<Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
						<Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
						<Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('project')}>Projects</Nav.Link>
					</Nav>
					<span className='navbar-text'>
						<div className='social-icon'>
							<a href='https://www.linkedin.com/in/gabriel-l-%C3%A9cuyer-r%C3%A9gimbald/'><img src={navIcon1} alt="linkedin"></img></a>
							<a href='https://www.facebook.com/Gabriel.Lecuyer'><img src={navIcon2} alt="facebook"></img></a>
						</div>
						<button
							className='vvd'
							onClick={() => window.open('https://www.linkedin.com/in/gabriel-l-%C3%A9cuyer-r%C3%A9gimbald/', '_blank')}
						>
							<span>Let's Connect</span>
						</button>
					</span>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Navigation