import { Col } from "react-bootstrap";
import { urlFor } from './../../client';

export const ProjectCard = ({ title, description, imgUrl, projectLink, tags }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <a href={projectLink} target="blank">
          <img src={urlFor(imgUrl)} alt={`${title}-image`}/>
          <div className="proj-txtx">
            <h4>{title}</h4>
            <span>{description}</span>
            <p className="proj-tags">Skills: {tags && tags.join(', ')}</p>
          </div>
        </a>
      </div>
    </Col>
  )
}
