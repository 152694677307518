import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
//import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useTypewriter } from "../hooks/useTypeWriter";

const Banner = ({ rotatingText = ["Web Developer", "Full Stack Developer", "Ecommerce Developer"], period = 2000 }) => {
    const [text] = useTypewriter(rotatingText, period);


  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Welcome to my Portfolio</span>
                <h1>{`Hi! I'm Gabriel `} <br/><span className="txt-rotate" data-period="1000" data-rotate='[ "Web Developer", "Web Designer", "UI/UX Designer" ]'><span className="wrap">{text}</span></span></h1>
                  <p>I specialize in web development, primarily working with e-commerce platforms like Magento 2 and WooCommerce to create custom solutions and streamline site functionality.
                    From custom checkouts and integrations to diverse functionality, I focus on building efficient, client-specific solutions that improve user experience.
                    I enjoy working across both front-end and back-end development, always aiming to deliver clean, robust code.
                    Staying up-to-date with the latest web technologies is a priority for me, so I’m constantly refining my skills to provide reliable, high-quality results.</p>
                  <button onClick={() => window.open('https://www.linkedin.com/in/gabriel-l-%C3%A9cuyer-r%C3%A9gimbald/', '_blank')}>Let’s Connect <ArrowRightCircle size={25} /></button>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner