import { useState, useEffect, useRef, useCallback } from "react";

export const useTypewriter = (toRotate, period = 2000) => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [typingSpeed, setTypingSpeed] = useState(150); // Default typing speed
  const [isPaused, setIsPaused] = useState(false);
  const timeoutRef = useRef(null);

  // Use useCallback to memoize tick so it doesn't trigger useEffect dependency warnings
  const tick = useCallback(() => {
    const currentTextIndex = loopNum % toRotate.length;
    const fullText = toRotate[currentTextIndex];

    const updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (!isDeleting && updatedText === fullText) {
      // When the text is fully typed, pause before deleting
      setIsPaused(true);
      setTimeout(() => {
        setIsDeleting(true);
        setIsPaused(false);
      }, period);
    } else if (isDeleting && updatedText === '') {
      // Once the text is fully deleted, move to the next word
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setTypingSpeed(150); // Reset typing speed
    } else if (isDeleting) {
      setTypingSpeed(50); // Faster speed while deleting
    }
  }, [isDeleting, loopNum, text, toRotate, period]);

  useEffect(() => {
    if (!isPaused) {
      timeoutRef.current = setTimeout(() => tick(), typingSpeed);
    }
    return () => clearTimeout(timeoutRef.current); // Clear timeout on cleanup
  }, [text, isDeleting, isPaused, typingSpeed, tick]); // Add tick to dependencies

  return [text, isPaused, typingSpeed];
};
