import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { motion } from 'framer-motion';
import { Tooltip } from 'react-tooltip';
import { MotionWrap } from '../wrapper';

import { urlFor, client } from './../client';

import './Skills.scss'

const Skills = () => {
    const [experiences, setExperiences] = useState([]);
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        const query = '*[_type == "experiences"]';
        const skillsQuery = '*[_type == "skills"]';

        client.fetch(query).then((data) => {
            const sortedData = data.sort((a, b) => {
                return parseInt(b.year) - parseInt(a.year);
            });

            setExperiences(sortedData);
        });



        client.fetch(skillsQuery).then((data) => {
            setSkills(data);
        });
    }, []);

    return (
        <Container id="skills">
            <Row className="align-items-center">
                <h2>Skills & Experiences</h2>
                <div className="app__skills-container">
                    <Col xs={12} md={6} xl={7}>
                        <div className='d-flex flex-row flex-wrap justify-content-center justify-content-sm-start'>
                            {skills.map((skill) => (
                                <motion.div
                                    whileInView={{ opacity: [0, 1] }}
                                    transition={{ duration: 0.5 }}
                                    className="app__skills-item app__flex"
                                    key={skill.name}
                                >
                                    <div
                                        className="app__flex"
                                        style={{ backgroundColor: skill.bgColor }}
                                    >
                                        <img src={urlFor(skill.icon)} alt={skill.name} />
                                    </div>
                                    <p className="p-text">{skill.name}</p>
                                </motion.div>
                            ))}
                        </div>
                    </Col>
                    <Col xs={12} md={6} xl={5} className='offset-xl-1'>
                        {experiences.map((experience) => (
                            <motion.div
                                className="app__skills-exp-item"
                                key={experience.year}
                            >
                                <div className="app__skills-exp-year">
                                    <p className="bold-text" >{experience.year}</p>
                                </div>
                                <motion.div className="app__skills-exp-works">
                                    {experience.works.map((work) => (
                                        <React.Fragment key={work.name}>
                                            <motion.div
                                                whileInView={{ opacity: [0, 1] }}
                                                transition={{ duration: 0.5 }}
                                                className="app__skills-exp-work"
                                                data-tip
                                                data-for={work.name}
                                            >
                                                <h4 className="bold-text">{work.name}</h4>
                                                <p className="p-text">{work.company}</p>
                                            </motion.div>
                                            <Tooltip
                                                id={work.name}
                                                effect="solid"
                                                arrowColor="#fff"
                                                className="skills-tooltip"
                                            >
                                                {work.desc}
                                            </Tooltip>
                                        </React.Fragment>
                                    ))}

                                </motion.div>
                            </motion.div>
                        ))
                        }
                    </Col>
                </div>
            </Row>
        </Container>
    );
}

export default MotionWrap(Skills, 'skills')
